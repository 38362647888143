<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-7">
        <div class="text-center pt-5">
          <router-link :to="{ name: 'home' }">
            <img src="/logo.svg" alt="logo" style="height: 80px" />
            <div class="mt-4">
              <img src="/theme/login.png" alt="login" style="height: 300px" />
            </div>
            <div class="caption mt-3">
              <h4>Have the ability to buy or sell carbon.</h4>
              <p class="text-muted">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
                doloremque?
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
