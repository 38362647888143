<template>
  <div class="auth">
    <div class="auth-content">
      <div class="side">
        <auth-side />
      </div>
      <div class="content">
        <div class="container">
          <div class="p-0 p-md-4">
            <div class="text-end mt-5 mt-md-0">
              <span class="text-primary">New on our platform? </span>
              <router-link :to="{ name: 'register' }" class="text-primary"
                ><strong>Create an account</strong></router-link
              >
            </div>
            <div class="text-center my-4 mobile-only">
              <router-link :to="{ name: 'home'}"><img src="/logo.svg" alt="logo"></router-link>
            </div>
            <div class="row mt-0 mt-md-5">
              <div class="col-md-7">
                <div class="card shadow">
                  <div class="card-body" v-show="! loading">
                    <h3>Sign In</h3>
                    <p class="text-muted">Lorem ipsum, dolor sit amet</p>
                    <alert type="danger" v-if="show_error">Invalid email and/or password.</alert>
                    <form @submit.prevent="submit" id="login_form">
                      <div class="form-floating mb-3">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          name="email"
                          v-model="user.email"
                        />
                        <label for="email">Email</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="Password"
                          name="password"
                          v-model="user.password"
                        />
                        <label for="password">Password</label>
                      </div>
                      <div>
                        <button class="btn btn-primary w-100">
                          Sign In<i class="fa fa-sign-in ms-2"></i>
                        </button>
                      </div>
                    </form>
                    <div class="mt-4 text-end">
                      <router-link :to="{ name: 'forgot-password' }">Forgot Password?</router-link>
                    </div>
                  </div>
                  <div class="card-body" v-if="loading">
                    <loading />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthSide from "@/components/site/AuthSide";

export default {
  components: {
    AuthSide
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },

  data() {
    return {
      user: {},
      show_error: false,
      loading: false
    }
  },

  methods: {
    submit() {
      this.loading = true
      this.show_error = false
      let data = this.user
      data.form_id = 'login_form'

      this.$axios.post('/api/v1/login', data).then(response => {
        this.$storage.set('bearer_token', response.data.access_token)
        window.location.href = this.$router.resolve({ name: 'dashboard' }).href
      }).catch(() => {
        this.show_error = true
        this.loading = false
      })
    }
  }
};
</script>
